@tailwind base;
@tailwind components;
@tailwind utilities;

br.small {
	display: none;
}
br.medium {
	display: none;
}
br.large {
	display: block;
}
@media only screen and (max-width: 1068px) {
	br.small {
		display: none;
	}
	br.medium {
		display: block;
	}
	br.large {
		display: none;
	}
}
@media only screen and (max-width: 734px) {
	br.small {
		display: block;
	}
	br.medium {
		display: none;
	}
	br.large {
		display: none;
	}
}

body{
  background-color: hsl(0, 0%, 0%);
  font-family: "Raleway", sans-serif;
}

body span,p,h1,h2,h3,h4,a{
 color: hsl(0, 0%, 100%);
}

.container-side-by-side {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Dikey hizalama */
  gap: 100px;
  margin-bottom: 100px;
}

@media only screen and (max-width: 1068px){
  .container-side-by-side{
    padding: 20px;
  }
}

.container-side-by-side .about {
  max-width: 400px;
  width: 100%;
}

.container-side-by-side .about p{
  color: rgba(255, 255, 255, 0.7);
}

.container-side-by-side .experience {
  max-width: 500px; 
  width: 100%; 
}

@media only screen and (max-width: 734px){
  .container-side-by-side{
    flex-direction: column;
    padding: 20px;
  }
}

/* Header CSS Start */

.navbar ul li a {
  padding: 8px 35px;
}

.navbar ul li a:hover {
  text-decoration: underline;
}

.navbar ul li a, .menu-button {
  color: hsl(0, 0%, 100%);
}

.menu-button {
  display: none;
}

@media (max-width: 768px) {
  .navbar ul .navbar-item{
    display: none; /* Ana menüyü gizle */
  }
  .menu-button {
    display: block; /* Menü düğmesini göster */
    color: white;
    background: none;
    border: none;
    font-size: 1.2em;
  }
}

.mobile-menu {
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  flex-direction: column;
  margin-top: 50%;
  align-items: center;
  gap: 20px;
  z-index: 1000;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
    pointer-events: none; /* Prevents clicking when closed */
}

.mobile-menu.open {
  display: flex;
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto; /* Allows clicking when open */
}


.mobile-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-menu li {
  margin: 10px 0;
}

.mobile-menu a {
  color: white;
  text-decoration: none;
  font-size: 1.5em;
}

/* Header CSS End */

/* Hero CSS Start */

@media only screen and (max-width: 734px) {
  .animated-element {
    animation: slide 2s ease-in-out infinite; /* Daha uzun bir animasyon süresi */
  }
}

.section-hero{
  margin-bottom: 170px;
}

.section-hero-content{
  margin-top: 150px;
  padding-left: 180px;
}

@media only screen and (max-width: 1068px) {
  .section-hero-content{
    margin-top: 80px;
    padding-left: 50px;
  }
  .section-hero{
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 734px) {
  .section-hero-content {
      margin-top: 70px;
      padding-left: 27px;
  }
}

.headline, .headline-text{
  font-size: 35px;
}

.headline-text{
  color: rgba(255, 255, 255, 0.7);
}

.section-hero .buttons{
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 35px;
}

.section-hero .buttons .icon-arrow{
  display: flex;
  align-items: center;
  gap: 10px;
}

.section-hero .buttons .icon-arrow span:hover{
  text-decoration: underline;
}

.btn {
  --border-color: linear-gradient(-45deg, #ffae00, #7e03aa, #00fffb);
  --border-width: 0.125em;
  --curve-size: 0.5em;
  --blur: 30px;
  --bg: #080312;
  --color: #afffff;
  color: var(--color);
  cursor: pointer;
  /* use position: relative; so that BG is only for .btn */
  position: relative;
  isolation: isolate;
  display: inline-grid;
  place-content: center;
  padding: 0.5em 1.5em;
  font-size: 17px;
  border: 0;
  text-transform: uppercase;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.6);
  clip-path: polygon(
    /* Top-left */ 0% var(--curve-size),
    var(--curve-size) 0,
    /* top-right */ 100% 0,
    100% calc(100% - var(--curve-size)),
    /* bottom-right 1 */ calc(100% - var(--curve-size)) 100%,
    /* bottom-right 2 */ 0 100%
  );
  transition: color 250ms;
}

.btn::after,
.btn::before {
  content: "";
  position: absolute;
  inset: 0;
}

.btn::before {
  background: var(--border-color);
  background-size: 300% 300%;
  animation: move-bg7234 5s ease infinite;
  z-index: -2;
}

@keyframes move-bg7234 {
  0% {
    background-position: 31% 0%;
  }

  50% {
    background-position: 70% 100%;
  }

  100% {
    background-position: 31% 0%;
  }
}

.btn::after {
  background: var(--bg);
  z-index: -1;
  clip-path: polygon(
    /* Top-left */ var(--border-width)
      calc(var(--curve-size) + var(--border-width) * 0.5),
    calc(var(--curve-size) + var(--border-width) * 0.5) var(--border-width),
    /* top-right */ calc(100% - var(--border-width)) var(--border-width),
    calc(100% - var(--border-width))
      calc(100% - calc(var(--curve-size) + var(--border-width) * 0.5)),
    /* bottom-right 1 */
      calc(100% - calc(var(--curve-size) + var(--border-width) * 0.5))
      calc(100% - var(--border-width)),
    /* bottom-right 2 */ var(--border-width) calc(100% - var(--border-width))
  );
  transition: clip-path 500ms;
}

.btn:where(:hover, :focus)::after {
  clip-path: polygon(
    /* Top-left */ calc(100% - var(--border-width))
      calc(100% - calc(var(--curve-size) + var(--border-width) * 0.5)),
    calc(100% - var(--border-width)) var(--border-width),
    /* top-right */ calc(100% - var(--border-width)) var(--border-width),
    calc(100% - var(--border-width))
      calc(100% - calc(var(--curve-size) + var(--border-width) * 0.5)),
    /* bottom-right 1 */
      calc(100% - calc(var(--curve-size) + var(--border-width) * 0.5))
      calc(100% - var(--border-width)),
    /* bottom-right 2 */
      calc(100% - calc(var(--curve-size) + var(--border-width) * 0.5))
      calc(100% - var(--border-width))
  );
  transition: 200ms;
}

.btn:where(:hover, :focus) {
  color: #fff;
}

/* Hero CSS End */

/* About CSS Start */



/* About CSS End */

/* Experience CSS Start */



/* Experience CSS End */

/* Tech CSS Start */

@media only screen and (min-width: 1068px){
  .tech {
    margin: 130px;
  }
}

.tech {
  border-radius: 20px 0px;
}

.tech .tech-item {
  background-color: white;
  padding: 30px;
  border-radius: 100px;
  box-shadow: inset rgba(60, 70, 85, 0.5) 0px 0px 40px 0px, inset rgba(60, 70, 85, 0.5) 0px 0px 40px 0px, inset rgba(0, 0, 0, 1) 0px 0px 36px -24px;
  transition: transform 0.3s ease; /* Geçiş süresi ve hızı */
}

.tech .tech-item:hover{
  transform: translateY(-10px); /* Yukarı doğru 10px kaydır */
}

@media only screen and (max-width: 1068px){
  .tech .tech-item{
  }
}


/* Tech CSS End */

/* Footer CSS Start */

.footer {
  text-align: center;
  padding: 10px;
  font-size: 0.9em; /* Yazı boyutunu küçültmek için */
  border-top: 1px solid #ddd; /* Üst çizgiyle ayırmak için */
}

/* Footer CSS End */


